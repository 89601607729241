import { HorizontalBar } from 'vue-chartjs';

export default {
    name: 'DoubleBarChart',
    extends: HorizontalBar,
    props: {
        data: {
            type: Array,
            required: true
        },
        labels: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            datacollection: {
                labels: this.labels,
                datasets: this.getDataSet()
            },

            options: {
                legend: {
                    display: true
                },
                responsive: true,
                maintainAspectRatio: false
            }
        };
    },
    mounted() {
        this.renderChart(this.datacollection, this.options);
    },
    methods: {
        getDataSet() {
            let datasets = [];
            this.data.forEach(dataset => {
                datasets.push({
                    backgroundColor: dataset.background,
                    borderColor: dataset.background,
                    borderWidth: 1,
                    data: dataset.data,
                    label: dataset.label
                });
            });
            return datasets;
        }
    }
};
