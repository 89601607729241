import { Line } from 'vue-chartjs';

export default {
    name: 'DoubleLineChart',
    extends: Line,
    props: {
        data: {
            type: Array,
            required: true
        },
        labels: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            datacollection: {
                labels: this.labels,
                datasets: this.getDataSet()
            },

            options: {
                legend: {
                    display: true
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: this.getScalesYAxe()
                }
            }
        };
    },
    mounted() {
        this.renderChart(this.datacollection, this.options);
    },
    methods: {
        getDataSet() {
            let datasets = [];
            this.data.forEach(dataset => {
                datasets.push({
                    label: dataset.label,
                    fill: true,
                    lineTension: 0.5,
                    pointHoverBorderWidth: 2,
                    pointRadius: 4,
                    pointHitRadius: 10,
                    yAxisID: dataset.label,
                    data: dataset.data,
                    backgroundColor: dataset.background
                });
            });
            return datasets;
        },
        getScalesYAxe() {
            let yAxes = [];
            this.data.forEach(dataset => {
                yAxes.push({
                    id: dataset.label,
                    type: 'linear',
                    position: dataset.position
                });
            });

            return yAxes;
        }
    }
};
