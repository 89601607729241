<template>
    <div class="section">
        <client-period-filters></client-period-filters>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card class="mb-3">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h5>{{ $t('client.pages.synthesis.lineChart.card.labels.title') }}</h5>
                        </div>
                        <div v-if="!refreshLineChartData">
                            <h5>
                                {{
                                    $t('client.pages.synthesis.lineChart.card.labels.total', {
                                        count: rawData.total_count,
                                        amount: $n(rawData.total_amount, 'currency')
                                    })
                                }}
                            </h5>
                        </div>
                    </div>
                    <spinner v-if="refreshLineChartData"></spinner>
                    <double-line-chart
                        :data="lineChartData"
                        :labels="labels"
                        v-if="!refreshLineChartData && rawData.months.length > 0"
                    ></double-line-chart>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :title="$t('client.pages.synthesis.barChart.card.labels.title')" title-tag="h5" class="mb-3">
                    <spinner v-if="refreshBarChartData"></spinner>
                    <double-bar-chart
                        :data="barChartData"
                        :labels="labels"
                        v-if="!refreshBarChartData && barChartData.length > 0"
                    ></double-bar-chart>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import ClientPeriodFilters from '@/components/client/dashboard/ClientPeriodFilters';
import InputFilterEvents from '@/views/client/InputFilterEvents';
import DoubleLineChart from '@/components/shared/chart/DoubleLineChart';
import DoubleBarChart from '@/components/shared/chart/DoubleBarChart';
import { EVENTS } from '@/constants/client/events';
import { DashboardRepository } from '@/repositories';
import Spinner from '@/components/shared/element/Spinner';

export default {
    name: 'ClientDashboardSynthesis',
    extends: InputFilterEvents,
    components: {
        ClientPeriodFilters,
        DoubleLineChart,
        DoubleBarChart,
        Spinner
    },
    data: () => ({
        lineChartData: null,
        labels: null,
        rawData: null,
        refreshLineChartData: true,
        refreshBarChartData: true,
        barChartData: null
    }),
    created() {
        this.getLineChartData();
        this.getBarChartData();
        this.$on(EVENTS.DASHBOARD.INPUT_CLIENTS_AND_PERIOD_FILTER_CHANGE, () => {
            this.getLineChartData();
            this.getBarChartData();
        });
    },
    methods: {
        getLineChartData() {
            this.refreshLineChartData = true;
            DashboardRepository.getTimeSynthesis(this.getFilters())
                .then(response => {
                    this.refreshLineChartData = false;
                    this.rawData = response.data.data;
                    this.lineChartData = [
                        {
                            label: this.$i18n.t('client.pages.synthesis.lineChart.card.legend.numberOfInterventions'),
                            data: response.data.data.months.map(a => a.count),
                            position: 'left',
                            background: 'rgba(170, 170, 170, 0.6)'
                        },
                        {
                            label: this.$i18n.t('client.pages.synthesis.lineChart.card.legend.totalAmount'),
                            data: response.data.data.months.map(a => a.amount),
                            position: 'right',
                            background: 'rgba(238, 119, 119, 0.6)'
                        }
                    ];
                    this.labels = response.data.data.months.map(
                        a => this.$i18n.t('shared.general.months.shortForm.' + String(a.month).padStart(2, '0')) + ' - ' + a.year
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getBarChartData() {
            this.refreshLineChartData = true;
            DashboardRepository.getAmountSynthesis(this.getFilters())
                .then(response => {
                    this.refreshBarChartData = false;
                    this.barChartData = [
                        {
                            label: this.$i18n.t('client.pages.synthesis.lineChart.card.legend.numberOfInterventions'),
                            data: response.data.data.map(a => a.percentage_amount),
                            background: 'rgba(170, 170, 170, 0.6)'
                        },
                        {
                            label: this.$i18n.t('client.pages.synthesis.lineChart.card.legend.totalAmount'),
                            data: response.data.data.map(a => a.percentage_count),
                            background: 'rgba(238, 119, 119, 0.6)'
                        }
                    ];
                    this.labels = response.data.data.map(a => a.skill_name);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
</script>
